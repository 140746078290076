.toggle-switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-wrapper {
  display: flex;
}

.toggle-switch + label {
  cursor: pointer;
  text-indent: -9999px;
  width: 48px;
  height: 24px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  margin: 0;
}

.toggle-switch + label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.toggle-switch:checked + label {
  background: #bada55;
}

.toggle-switch:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggle-switch + label:active:after {
  width: 130px;
}
