// ****** Fonts ******
// -----------------------------------------
$font-family-text: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;
$font-size-standard: 16px;

// ****** Colors ******
// -----------------------------------------
$color-ci: #58c7d9;
$color-success: #4db02a;
$color-error: #ff443a;

$color-bg-body: #454747;
$color-bg-content: #454747;
$color-bg-selection: $color-ci;

$color-border-standard: rgba(48, 56, 65, 0.1);

$color-font-standard: #fff;
$color-font-lighter: lighten($color-font-standard, 40%);
$color-font-light: lighten($color-font-standard, 60%);
$color-font-selection: #ffffff;
$color-font-placeholder: lighten($color-font-lighter, 10%);

$color-link-standard: $color-ci;
$color-link-hover: #2c7c96;
$color-link-visited: $color-ci;

// ****** Breakpoints ******
// -----------------------------------------
$smartphone-xs: 320px;
$smartphone-p-width: 480px;
$smartphone-lc-width: 667px;
$tablet-width: 768px;
$desktop-s: 900px;
$desktop-sm: 1033px;
$desktop-m: 1100px;
$desktop-ml: 1300px;
$desktop-l: 1500px;
$desktop-xl: 1700px;
$desktop-xxl: 2000px;

// ****** Grid widths ******
// -----------------------------------------
$content-width: 1033px;
$content-width-l: 1440px;
$content-width-xl: 1620px;
