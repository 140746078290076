// ****** Checkboxes ******
// -----------------------------------------
.default-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  & + label {
    display: flex; // Use flexbox for layout
    align-items: flex-start; // Align items to the top
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    flex-shrink: 0;
    // display: inline-block;
    // vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #cccccc;
  }

  // Box checked
  &:checked + label:before {
    background: #58c7d9;
    border-color: #58c7d9;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
