$btn-bgcolor-secondary: #ccc;

a.btn,
.btn {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 2rem;
  padding-right: 2rem;
  display: inline-block;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: none;
  color: #fff;
  line-height: 1.2;
  white-space: nowrap;
  cursor: pointer;

  &--small {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.75rem;
  }

  &--primary,
  &--cta {
    background-color: $color-ci;

    &:hover {
      background-color: lighten($color-ci, 5%);
    }
  }

  &--order {
    background-color: #d95858;

    &:hover {
      background-color: lighten(#d95858, 5%);
    }

    &[disabled] {
      &:hover {
        background-color: #d95858;
      }
    }
  }

  &--secondary {
    background-color: $btn-bgcolor-secondary;

    &:hover {
      background-color: lighten($btn-bgcolor-secondary, 5%);
    }
  }

  &--next {
    border-radius: 54px;
    background-color: $color-ci;

    &:hover {
      background-color: lighten($color-ci, 5%);
    }
  }

  &--cta {
    font-size: 1.25rem;
  }

  &--back {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #cfcfcf;
    padding: 16px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--icon {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background-color: $color-ci;
    }
  }
}

.btn-container {
  display: flex;
  gap: 10px;
}
