/* ****** Radios ******
// ----------------------------------------- */
.default-radio {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 10px 0 0 25px; // Adjust padding to leave space for the custom radio circle
      font-size: 1rem;
      line-height: 25px; // Aligns the text vertically with the custom radio circle
      display: inline-block;
      vertical-align: middle; // Ensure vertical centering with the circle
      margin-right: 20px;
    }

    // Circle.
    & + label:before {
      content: '';
      margin-left: -25px; // Position circle left of the label text
      margin-right: 5px;
      display: inline-block;
      vertical-align: text-bottom; // Center circle relative to text
      width: 20px;
      height: 20px;
      border-radius: 50%; // Makes the box a circle
      background: white;
      border: 2px solid #cccccc;
      
    }

    // Circle checked
    &:checked + label:before {
      background: #58c7d9;
      border-color: #58c7d9;
      box-shadow: inset 0 0 0 4px white; // Adds a white dot in the center
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled circle.
    &:disabled + label:before {
      background: #ddd;
      border-color: #b8b8b8;
    }
}