@import '../scss/responsive';

label {
  display: block;
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 4px;
  font-weight: 600;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.form-set {
  &--inline {
    display: flex;
    justify-content: space-between;

    @include smartphone-wide {
      display: block;
    }

    .form-group {
      width: 48%;

      @include smartphone-wide {
        width: 100%;
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;

    button:first-child {
      margin-right: auto;
    }

    .spinner {
      margin-right: 24px;
    }
  }

  &--right {
    justify-content: flex-end;

    button:first-child {
      margin-right: 0;
    }
  }

  &__error {
    background-color: #de3131;
    color: #fff;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px 2%;
    line-height: 1.5;
    border-radius: 4px;
  }

  .form-group {
    &--zip {
      width: 20%;
      @include smartphone-wide {
        width: 100%;
      }
    }

    &--city {
      width: 76%;
      @include smartphone-wide {
        width: 100%;
      }
    }

    &--checkbox {
      padding-top: 16px;
      margin-bottom: 0;
    }
  }
}

.form-group {
  margin-bottom: 24px;
  position: relative;

  &__hint {
    display: block;
    font-size: 0.6rem;
    margin-top: 8px;
  }

  .mat-icon {
    position: absolute;
    right: 3%;
    top: 16px;
    font-size: 20px;

    & + .form-input {
      padding-right: 40px;
    }
  }
}

.form-input,
.form-textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  height: 48px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  color: #4e4e4e;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;

  @include smartphone-wide {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }

  &.ng-invalid.ng-touched {
    background-color: rgba(212, 81, 81, 0.11);
  }
}

.form-textarea {
  padding-top: 8px;
  padding-bottom: 8px;
  resize: none;
}

.form-error {
  color: #de3131;
  font-size: 0.75rem;
  margin-top: 8px;
}

/*
 * <select> - Lists
 */
.form-select {
  width: 100%;
  height: 48px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  position: relative;

  &:focus,
  &:active {
    outline: none;
  }

  &.error {
    border-color: $color-error;
  }

  &__item {
    background-color: transparent;
    width: 100%;
    padding: 10px 1rem;
    font-size: 1rem;
    line-height: 1;
    border: 0;
    border-radius: 4px;
    height: 48px;
    position: relative;
    z-index: 9;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 37px;
    color: #4e4e4e;

    @include smartphone-wide {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &.ng-invalid.ng-touched {
      background-color: rgba(212, 81, 81, 0.11);
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__icon {
    display: block;
    right: 3%;
    top: 50%;
    transform: translate(0, -50%);
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 10;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
