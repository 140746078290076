input[type='range'] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 60%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #3f3f3f;
  border: 0px solid #000101;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 40px;
  width: 40px;
  border-radius: 7px;
  background: #58c7d9;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #3f3f3f;
}
