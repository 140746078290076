@import 'responsive';

.ngx-datatable.pedics {
  // background: #fff;
  // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  //   0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  &.striped {
    .datatable-row-odd {
      background: #eee;
    }
  }

  &.single-selection,
  &.multi-selection,
  &.multi-click-selection {
    .datatable-body-row {
      &.active,
      &.active .datatable-row-group {
        background-color: #58c7d9;

        color: #fff;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: #58c7d9;

        color: #fff;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: #58c7d9;

        color: #fff;
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {
      &:hover,
      &:hover .datatable-row-group {
        background-color: #eee;

        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;

        .datatable-checkbox input[type='checkbox']:after {
          background-color: #eee;
        }
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: #ddd;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {
      &:hover,
      &:hover .datatable-row-group {
        background-color: #eee;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: #ddd;
      }

      &.active,
      &.active .datatable-row-group {
        background-color: #58c7d9;
        color: #fff;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: #58c7d9;
        color: #fff;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: #58c7d9;
        color: #fff;
      }
    }
  }

  /**
	 * Shared Styles
	 */
  .empty-row {
    text-align: center;
    padding: 24px 1.2rem;
    vertical-align: top;
    border-top: 0;
    background-color: #fff;
    color: #bdbdbd;
    border-radius: 8px;
    letter-spacing: 2px;
    font-size: 0.75rem;
    margin-bottom: 16px;
  }

  .empty-cell {
    letter-spacing: 2px;
    font-size: 0.75rem;
    color: #bdbdbd;
  }

  .loading-row {
    text-align: left;
    padding: 0.5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
  }

  /**
	 * Global Row Styles
	 */
  .datatable-header,
  .datatable-body {
    .datatable-row-left {
      background-color: #fff;
      background-position: 100% 0;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
    }

    .datatable-row-right {
      background-position: 0 0;
      background-color: #fff;
      background-repeat: repeat-y;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
    }
  }

  /**
	 * Header Styles
	 */
  .datatable-header {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .datatable-header-cell {
      text-align: left;
      padding: 0.9rem 1.2rem 0;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.54);
      vertical-align: bottom;
      font-size: 0.675rem;
      text-transform: uppercase;
      letter-spacing: 1.3px;

      .datatable-header-cell-wrapper {
        position: relative;
      }

      &.longpress {
        .draggable::after {
          transition: transform 400ms ease, opacity 400ms ease;
          opacity: 0.5;
          transform: scale(1);
        }
      }

      .draggable::after {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        height: 60px;
        width: 60px;
        background: #eee;
        border-radius: 100%;
        opacity: 1;
        filter: none;
        transform: scale(0);
        z-index: 9999;
        pointer-events: none;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }

      .datatable-checkbox {
        margin-top: -10px;
      }

      .datatable-checkbox input[type='checkbox']:after {
        background-color: #f7f7f7;
      }
    }

    .resize-handle {
      border-right: solid 1px #eee;
    }
  }

  /**
	 * Body Styles
	 */
  .datatable-body {
    .datatable-row-wrapper {
      background-color: #fff;
      margin-bottom: 16px;
      cursor: pointer;
    }
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-group-header {
      background: #f5f5f5;
      border-bottom: solid 1px #d9d8d9;
      border-top: solid 1px #d9d8d9;
    }

    .datatable-body-row {
      .datatable-body-cell {
        text-align: left;
        padding: 1.3rem 1.2rem;
        vertical-align: top;
        border-top: 0;
        color: rgba(0, 0, 0, 0.5);
        transition: width 0.3s ease;
        font-size: 14px;
        font-weight: 500;

        // cell active class
        // &.active {
        //  background: #0829e0
        // }
      }
      .datatable-body-group-cell {
        text-align: left;
        padding: 1.3rem 1.2rem;
        vertical-align: top;
        border-top: 0;
        color: rgba(0, 0, 0, 0.5);
        transition: width 0.3s ease;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .progress-linear {
      display: block;
      position: relative !important;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;
      position: absolute;

      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 5px;
        -webkit-transform: translate(0, 0) scale(1, 1);
        transform: translate(0, 0) scale(1, 1);
        background-color: #58c7d9;

        .bar {
          transition: all 0.2s linear;
          -webkit-animation: query 0.8s infinite
            cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);

          transition: -webkit-transform 0.2s linear;
          transition: transform 0.2s linear;
          background-color: #fff;

          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
        }
      }
    }
  }

  /**
	 * Footer Styles
	 */
  .datatable-footer {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);

    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;

      @include smartphone-wide {
        display: none;
      }
    }

    .datatable-pager {
      margin: 0 10px;

      li {
        vertical-align: middle;

        &.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
        }

        &.active a {
          background-color: rgba(158, 158, 158, 0.2);
          font-weight: bold;
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0 6px;
        border-radius: 3px;
        margin: 6px 3px;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom;

        &:hover {
          color: rgba(0, 0, 0, 0.75);
          background-color: rgba(158, 158, 158, 0.2);
        }
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 20px;
        line-height: 20px;
        padding: 0 3px;
      }
    }
  }

  // Summary row styles
  .datatable-summary-row {
    .datatable-body-row {
      background-color: #ddd;

      &:hover {
        background-color: #ddd;
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  margin-top: -8px;

  input[type='checkbox'] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-appearance: none;

    &:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid #dcdcdc;
      top: -10px;

      @include tablet {
        top: 2px;
      }
    }

    &:checked:before {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      height: 0.5rem;
      border-color: #58c7d9;
      border-top-style: none;
      border-right-style: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer;
      transition-duration: 0.2s;
      transition-timing-function: linear;
      transition-property: background;
    }
  }
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
