// ****** Breakpoints ******
// -----------------------------------------
$smartphone-xs: 320px;
$smartphone-p-width: 480px;
$smartphone-lc-width: 667px;
$tablet-width: 768px;
$desktop-s: 875px;
$desktop-sm: 1010px;
$desktop-m: 1100px;
$desktop-ml: 1300px;
$desktop-l: 1500px;
$desktop-xl: 1700px;
$desktop-xxl: 2000px;

/*
 * Media Queries
 */
@mixin smartphone-xs {
  @media (max-width: #{$smartphone-xs}) {
    @content;
  }
}
@mixin smartphone-p {
  @media (max-width: #{$smartphone-p-width}) {
    @content;
  }
}
@mixin smartphone-wide {
  @media (max-width: #{$smartphone-lc-width}) {
    @content;
  }
}
@mixin smartphone-lc {
  @media (max-width: #{$smartphone-lc-width}) and (orientation: landscape) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
@mixin desktop-s {
  @media (max-width: #{$desktop-s}) {
    @content;
  }
}
@mixin desktop-sm {
  @media (max-width: #{$desktop-sm}) {
    @content;
  }
}
@mixin desktop-m {
  @media (max-width: #{$desktop-m}) {
    @content;
  }
}
@mixin desktop-ml {
  @media (max-width: #{$desktop-ml}) {
    @content;
  }
}
@mixin desktop-l {
  @media (min-width: #{$desktop-l}) {
    @content;
  }
}
@mixin desktop-xl {
  @media (min-width: #{$desktop-xl}) {
    @content;
  }
}
@mixin desktop-xxl {
  @media (min-width: #{$desktop-xxl}) {
    @content;
  }
}
