/* You can add global styles to this file, and also import other style files */
@import '_variables';
@import '_mixins';
@import '_forms';
@import '_checkboxes';
@import '_switches';
@import '_buttons';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '_datatables';
@import '_responsive';
@import '_range';
@import '_radios';

// @import '~@swimlane/ngx-datatable/release/assets/icons.css';

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f7f7f7;
  color: #9c9c9c;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

hr {
  border: 0;
  height: 1px;
  border-bottom: solid 1px rgb(204, 206, 208);
}

p {
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 700;
}

address {
  font-style: normal;
  line-height: 1.5;
}

a:link,
a:visited {
  color: $color-ci;
  text-decoration: none;
  outline: none;
  transition: 0.2s ease color;
}

a:hover,
a:active {
  color: $color-ci;
  outline: none;
  text-decoration: none;
}

/*
 * HEADLINES
 */
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #4e4e4e;

  @include tablet {
    line-height: 1.5;
  }
}

h1 {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 5px;

  @include smartphone-wide {
    letter-spacing: normal;
    font-size: 1.375rem;
  }
}

h2 {
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 4px;
}

h3 {
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

h4 {
  font-weight: 400;
  font-size: 1rem;
}

/*
 * Clearfix for floats
 */
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.clearfix:after {
  clear: both;
}

.material-icons {
  vertical-align: middle;
  font-size: 16px;
  margin-top: -3px;
}

.ped-card {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 5%;
  padding-right: 5%;

  &--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5%;
    grid-row-gap: 40px;

    @include smartphone-wide {
      grid-template-columns: 1fr;
    }
  }

  &__label {
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__value {
    font-size: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  p {
    margin-bottom: 32px;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ped-cc {
  width: 50px;
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
  }
}

.datatable-row-wrapper:hover {
  .assessment-details__delete-conf {
    opacity: 1;
    pointer-events: all;
  }
}

.mat-date-range-input-mirror {
  width: 80px;
}

// Date range picker
.mat-date-range-input-container {
  align-items: start !important;
}

.mat-date-range-input-separator  {
  margin: 0;
}

.mat-date-range-input {
  display: flex !important;
  align-items: center;
  width: 200px !important;

  @include smartphone-wide {
    width: 100% !important;
  }
}

.mat-date-range-input-start-wrapper {
  width: 140px;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: currentColor !important;
  margin-right: 8px !important;
}

.mat-calendar-body-selected {
  background-color: #58c7d9;
  color: #fff !important;
}

.mat-datepicker-toggle-active {
  color: #58c7d9;
}
.mat-calendar-body-in-range::before {
  background: rgba(55, 188, 199, 0.2) !important;
}

.mat-dialog-container {
  max-height: 70vh !important;
}

mat-datepicker-toggle {
  display: flex;
  align-items: center;
  margin-left: -8px;
}

.hide-tablet {
  @include tablet {
    display: none;
  }
}

.hide-smartphone-wide {
  @include smartphone-wide {
    display: none;
  }
}

.hide-smartphone-p {
  @include smartphone-p {
    display: none;
  }
}
